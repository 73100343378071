// import { SlackUtils } from '../components/Slack';

export function Component() {
  const handleClick = async () => {
    // window.location.href = await SlackUtils.GenerateSlackInstallURL({
    //   scenario: 'connect',
    // });
    console.log('xyz');
  };

  return (
    <div className='w-full h-full text-white flex items-center justify-center '>
      <button
        className={`btn-primary flex items-center justify-center px-4 py-2`}
        onClick={handleClick}
        type='button'
      >
        <div>Add to Slack</div>
      </button>
    </div>
  );
}
